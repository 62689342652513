
.goods-hall {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 100px);
    min-width: 1000px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 0 17px 1px rgba(11, 12, 28, 0.09);
    padding: 17px 30px;
    box-sizing: border-box;
    .title {
        height: 44px;
        line-height: 44px;
        background-color: #fff;
        color: #3A3567;
        font-size: 18px;
    }
    .goods-list {
        flex: 1;
        height: 1%;
        padding-left: 24px;
        margin-right: -20px;
        display: flex;
        flex-wrap: wrap;
        .list-item {
            /*width: calc(16% - 14px);*/
            width: 215px;
            height: 323px;
            margin: 20px 20px 8px 0;
            border-radius: 4px;
            border: 1px solid #EEEEEE;
            &:hover {
                cursor: pointer;
                box-shadow: 0px 2px 8px 0px rgba(96, 86, 241, 0.27);
                border-radius: 0px 4px 4px 0px;
                .goods-handle {
                    opacity: 0.8;
                    animation: fadeInBottom .5s;
                }
            }
            .img {
                position: relative;
                /*width: 100%;*/
                width: 215px;
                height: 215px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .goods-handle {
                display: flex;
                align-items: center;
                width: 100%;
                position: absolute;
                bottom: 0;
                background: #FF5200;
                opacity: 0;
                .handle-item {
                    width: 49.7%;
                    height: 28px;
                    line-height: 28px;
                    color: #fff;
                    text-align: center;
                    cursor: pointer;
                }
                .handle-item-span {
                    display: inline-block;
                    width: 0.6%;
                    background-color: #fff;
                    height: 16px;
                }
            }
            .goods-info {
                padding: 10px;
                .goods-name {
                    height: 34px;
                    font-size: 12px;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                }
                .handle {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 10px;
                    .handle-item {
                        display: flex;
                        flex-direction: column;
                        width: 45%;
                        text-align: center;
                        .num {
                            font-size: 16px;
                            color: #FF5400;
                            font-weight: 500;
                        }
                        .text {
                            font-size: 12px;
                            color: #999999;
                            margin-top: 5px;
                        }
                    }
                }
            }
        }
    }
    .pages-center {
        background-color: #fff;
        margin: 0;
        padding: 10px 0;
    }
    .el-table__body-wrapper {
        .cell {
            .goods_cover {
                width: 80px;
                height: 80px;
                display: flex;
                flex-direction: column;
                align-items: center;
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            .goods_name {
                margin-left: 5px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: calc(100% - 85px);
                text-align: left;
            }
            .goods_price {
                color: #FF0000;
            }
        }
    }
    ::v-deep .el-table .el-table__body .el-table__row td:first-child .cell {
        display: flex;
        align-items: center;
    }
}
@media screen and (width: 1680px) {
    .goods-list {
        padding-left: 0 !important;
    }
    .list-item {
        margin: 20px 12px 8px 0!important;
    }
}
@media screen and (width: 1920px) {
    .list-item {
        margin: 20px 20px 0 21px !important;
    }
}
@keyframes fadeInBottom {
    0% {
        opacity: 0;
        transform: translateY(5px);
        -webkit-transform: translateY(5px);
    }
    100% {
        opacity: 0.8;
        transform: translateY(0);
        -webkit-transform: translateY(0);
    }
}
